/**
 * @author 熊志伟
 */

import { cn, en } from '@/locale'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'zh-CN',
  messages: {
    'zh-CN': cn,
    'en-US': en,
  },
}))
