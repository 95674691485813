/*
 * @Author: huangliangmiao 1127255342@qq.com
 * @Date: 2024-02-28 15:55:47
 * @LastEditors: 熊志伟
 * @LastEditTime: 2024-08-16 14:21:17
 * @FilePath: \dcy-web\apps\admin\locale\zh-CN.ts
 * @Description: 中文多语言入口
 */
import localeSettings from './zh-CN/settings'

export default {
  '资源首页': '资源首页',
  'menu.dashboard': '仪表盘',
  'menu.server.dashboard': '仪表盘-服务端',
  'menu.server.workplace': '工作台-服务端',
  'menu.server.monitor': '实时监控-服务端',
  'menu.list': '列表页',
  'menu.result': '结果页',
  'menu.exception': '异常页',
  'menu.form': '表单页',
  'menu.profile': '详情页',
  'menu.visualization': '数据可视化',
  'menu.user': '个人中心',
  'menu.arcoWebsite': 'Arco Design',
  'menu.index': '首页',
  'menu.my.data': '个人资料',
  'menu.hi.name': '你好，{name}',
  'menu.hi': '嵌套页',
  'menu.vitesse': 'Vitesse',
  'menu.nest': '子路由',
  'menu.hello': '你好，世界',
  'menu.faq': '常见问题',
  'navbar.docs': '文档中心',
  'navbar.action.locale': '切换为中文',
  'index': '首页',
  'intro.desc': '固执己见的 Vite 项目模板',
  'intro.dynamic-route': '动态路由演示',
  'intro.hi': '你好，{name}',
  'intro.aka': '也叫',
  'intro.whats-your-name': '输入你的名字',
  'hi.nest': '这是一个嵌套路由',
  'hi.hello': '你好，世界',
  'button.about': '关于',
  'button.back': '返回',
  'button.go': '确定',
  'button.home': '首页',
  'button.toggle_dark': '切换深色模式',
  'toggle_langs': '切换语言',
  'not-found': '未找到页面',
  'login.username': '请输入手机号',
  'login.password': '请输入密码',
  'login.saveLoginStatus': '保存登录',
  'login.forgetPassword': '忘记密码?',
  'login.login': '登录',
  'login.noAccount': '还没有账号，立即注册',
  'login.selectIdentity': '请选择身份',
  'register.register': '注册',
  'register.backLogin': '返回登录',
  'register.imteacher': '我是教师',
  'register.imstudent': '我是学生',
  'register.name': '姓名',
  'register.organizationId': '学校',
  'register.majorId': '专业',
  'register.grade': '年级',
  'register.courseIds': '课程',
  'register.titleId': '职称',
  'register.idCard': '身份证',
  'register.phone': '手机号',
  'register.verifyCode': '验证码',
  'register.password': '密码',
  'register.newPassword': '新密码',
  'register.confirmPassword': '确认密码',
  'register.namePlaceholder': '请填写真实姓名',
  'register.schoolPlaceholder': '请选择学校',
  'register.majorPlaceholder': '请选择专业',
  'register.gradePlaceholder': '请选择入学年份',
  'register.coursePlaceholder': '请选择课程(可多选)',
  'register.titlePlaceholder': '请选择职称',
  'register.idCardPlaceholder': '请填写身份证号',
  'register.phonePlaceholder': '请填写手机号',
  'register.passwordPlaceholderTip': '请填写密码',
  'register.verifyCodePlaceholder': '请填写正确的短信验证码',
  'register.newPasswordPlaceholder': '请输入新密码',
  'register.passwordPlaceholder': '请输入密码',
  'register.passwordHelp': '密码要求6-12位，且需要包含字母、数字两种元素。',
  'register.oldPassword': '请填写原密码',
  'register.passwordConfirm': '请确认密码',
  'register.passwordWrongConfirm': '两次密码输入不一致',
  'register.agree': '我同意',
  'register.agreement': '《达成云注册协议》',
  'register.agreePlease': '请同意《达成云注册协议》《达成云隐私协议》',
  'register.registNow': '立即注册',
  'register.success': '注册成功',
  'register.registSuccess': '恭喜您，注册信息提交成功！我们将尽快完成审核并通知您',
  'register.getVerifyCode': '获取验证码',
  'register.fogottenSuccess': '找回密码成功',
  'register.updatePasswordSuccess': '修改密码成功',
  'register.hasSend': '已发送验证码',
  'register.studentCode': '学号',
  'register.studentCodePlaceholder': '请填写学生学号',
  'register.idCardTooltip': '仅用于达成云平台实名注册使用',
  'register.privacy': '《达成云隐私协议》',
  'register.idCardAttachment': '身份证附件',
  ...localeSettings,
}
